<template>
    <div class="users-management">
      <h2>User Management</h2>
      
      <button class="btn btn-primary mb-3" @click="showAddUserModal = true">Add New User</button>
      
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Organization</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user._id">
            <td>{{ user.name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.role }}</td>
            <td>{{ user.organization ? user.organization.name : 'N/A' }}</td>
            <td>
              <button class="btn btn-sm btn-info mr-2" @click="editUser(user)">
              <i class="fas fa-edit"></i> Edit
            </button>
            <button class="btn btn-sm btn-danger" @click="deleteUser(user._id)">
              <i class="fas fa-trash-alt"></i> Delete
            </button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <!-- Add User Modal -->
      <div class="modal" tabindex="-1" role="dialog" v-if="showAddUserModal">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Add New User</h5>
              <button type="button" class="close" @click="showAddUserModal = false">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="addUser">
                <div class="form-group">
                  <label for="name">Name</label>
                  <input type="text" class="form-control" id="name" v-model="newUser.name" required>
                </div>
                <div class="form-group">
                  <label for="email">Email</label>
                  <input type="email" class="form-control" id="email" v-model="newUser.email" required>
                </div>
                <div class="form-group">
                  <label for="role">Role</label>
                  <select class="form-control" id="role" v-model="newUser.role" required>
                    <option value="super_admin">Super Admin</option>
                    <option value="institution_admin">Institution Admin</option>
                    <option value="facilitator">Facilitator</option>
                    <option value="end_user">End User</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="organization">Organization</label>
                  <select class="form-control" id="organization" v-model="newUser.organization">
                    <option value="">None</option>
                    <option v-for="org in organizations" :key="org._id" :value="org._id">{{ org.name }}</option>
                  </select>
                </div>
                <button type="submit" class="btn btn-primary">Add User</button>
              </form>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Edit User Modal (similar to Add User Modal, but with pre-filled data) -->
      <!-- Implement this modal similar to the Add User Modal -->
  
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue'
  import { fetchUsers, createUser, } from '@/api'
  import { fetchOrganizations } from '@/api'  // Assuming you have this API call
  
  export default {
    name: 'SuperAdminUsers',
    setup() {
      const users = ref([])
      const organizations = ref([])
      const showAddUserModal = ref(false)
      const newUser = ref({
        auth0Id:'1234567',
        name: '',
        email: '',
        role: '',
        organization: ''
      })
  
      const loadUsers = async () => {
        try {
          const response = await fetchUsers()
          users.value = response.data
        } catch (error) {
          console.error('Error fetching users:', error)
          // Implement error handling (e.g., show an error message to the user)
        }
      }
  
      const loadOrganizations = async () => {
        try {
          const response = await fetchOrganizations()
          organizations.value = response.data
        } catch (error) {
          console.error('Error fetching organizations:', error)
          // Implement error handling
        }
      }
  
      const addUser = async () => {
        try {
          await createUser(newUser.value)
          await loadUsers()
          showAddUserModal.value = false
          newUser.value = { name: '', email: '', role: '', organization: '' }
        } catch (error) {
          console.error('Error creating user:', error)
          // Implement error handling
        }
      }
  
    /*  const editUser = (user) => {
        // Implement edit functionality
        console.log('Edit user:', user)
        // You would typically set the user data to a form and show an edit modal
      }
  
      const deleteUser = async (userId) => {
        if (confirm('Are you sure you want to delete this user?')) {
          try {
            await deleteUser(userId)
            await loadUsers()
          } catch (error) {
            console.error('Error deleting user:', error)
            // Implement error handling
          }
        }
      }
      */
      onMounted(() => {
        loadUsers()
        loadOrganizations()
      })
  
      return {
        users,
        organizations,
        showAddUserModal,
        newUser,
        addUser,
        //editUser,
        //deleteUser
      }
    }
  }
  </script>
  
  <style scoped>
  .users-management {
    padding: 20px;
  }
  
  .modal {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-dialog {
    margin-top: 100px;
  }
  </style>