import { axiosInstance } from '@/plugins/axios'

const API_URL = process.env.VUE_APP_API_URL || 'https://lms.punyahacademy.com/api'
export const fetchOrganizations = () => axiosInstance.get(`${API_URL}/organizations`)
export const createOrganization = (orgData) => axiosInstance.post(`${API_URL}/organizations`, orgData)
export const updateOrganization = (orgId, orgData) => axiosInstance.put(`${API_URL}/organizations/${orgId}`, orgData)
export const deleteOrganization = (orgId) => axiosInstance.delete(`${API_URL}/organizations/${orgId}`)

export const fetchUsers = () => axiosInstance.get(`${API_URL}/users`)
export const createUser = (userData) => axiosInstance.post(`${API_URL}/users`, userData)
export const updateUser = (userId, userData) => axiosInstance.put(`${API_URL}/users/${userId}`, userData)
export const deleteUser = (userId) => axiosInstance.delete(`${API_URL}/users/${userId}`)

export const fetchCourses = () => axiosInstance.get(`${API_URL}/courses`)
export const createCourse = (courseData) => axiosInstance.post(`${API_URL}/courses`, courseData)
export const updateCourse = (courseId, courseData) => axiosInstance.put(`${API_URL}/courses/${courseId}`, courseData)
export const deleteCourse = (courseId) => axiosInstance.delete(`${API_URL}/courses/${courseId}`)

export const fetchStats = () => axiosInstance.get(`${API_URL}/stats`)