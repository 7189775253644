<template>
  <div class="welcome-screen d-flex align-items-center justify-content-center">
    <div class="content text-center">
      <h1 class="display-4 mb-4 animate__animated animate__fadeInDown">Welcome to the LMS</h1>
      <p class="lead mb-5 animate__animated animate__fadeIn animate__delay-1s">Please log in to access the system</p>
      <div class="d-flex justify-content-center">
        <button id="loginButton" class="btn btn-primary btn-lg animate__animated animate__fadeInUp animate__delay-2s me-3" @click="openAuthPopup">
          <i class="fas fa-sign-in-alt mr-2"></i>Log In
        </button>
        <button id="guestButton" class="btn btn-secondary btn-lg animate__animated animate__fadeInUp animate__delay-2s" @click="guestLogin">
          <i class="fas fa-user-secret mr-2"></i>Guest Access
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'WelcomeScreen',
  setup() {
    const router = useRouter()
    const store = useStore()
    const isAuthenticated = ref(false)

    const openAuthPopup = () => {
      const authUrl = 'https://auth.ivislabs.com/login?solution=LMS'  // Replace with your actual auth URL
      const authWindow = window.open(authUrl, 'Auth', 'width=500,height=600')

      window.addEventListener('message', async (event) => {
        if (event.origin !== 'https://auth.ivislabs.com') return;
        alert(event.data.user.username);
        alert(event.data.user.email);
        alert(event.data.user.solution);
        alert(event.data.user.role);  
        if (event.data.type === 'AUTH_SUCCESS') {
          authWindow.close()
          const token = event.data.token
          await store.dispatch('setAuth', token)
          await store.dispatch('fetchUserInfo')
          isAuthenticated.value = true
          navigateToApp()
        }
      }, false)
    }
    const guestLogin = () => {
      router.push('/guest/dashboard')
    }
    const navigateToApp = () => {
      const userRole = store.getters.userRole
      console.log("USER ROLE:"+userRole)
      switch (userRole) {
        case 'superadmin':
          router.push('/super-admin/dashboard')
          break
        case 'institution_admin':
          router.push('/admin/dashboard')
          break
        case 'facilitator':
          router.push('/facilitator/dashboard')
          break
        case 'enduser':
          router.push('/user/dashboard')
          break
        default:
          console.error('Unknown user role')
      }
    }

    return {
      openAuthPopup,
      guestLogin,
      isAuthenticated
    }
  }
}
</script>

<style scoped>
/* Styles remain the same */
</style>