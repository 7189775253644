<template>
    <div class="organizations">
      <h1 class="h2 mb-3">Manage Organizations</h1>
      
      <button class="btn btn-primary mb-3" @click="showCreateOrgForm = true">Create New Organization</button>
      
      <div v-if="showCreateOrgForm" class="card mb-3">
        <div class="card-body">
          <h5 class="card-title">Create New Organization</h5>
          <form @submit.prevent="handleCreateOrganization">
            <div class="form-group">
              <label for="orgName">Organization Name</label>
              <input type="text" class="form-control" id="orgName" v-model="newOrg.name" required>
            </div>
            <div class="form-group">
              <label for="orgDomain">Domain</label>
              <input type="text" class="form-control" id="orgDomain" v-model="newOrg.domain" required>
            </div>
            <button type="submit" class="btn btn-success">Create</button>
            <button type="button" class="btn btn-secondary ml-2" @click="showCreateOrgForm = false">Cancel</button>
          </form>
        </div>
      </div>
      
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Domain</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="org in organizations" :key="org._id">
            <td>{{ org.name }}</td>
            <td>{{ org.domain }}</td>
            <td>
              <button class="btn btn-sm btn-info mr-2" @click="editOrganization(org)">
              <i class="fas fa-edit"></i> Edit
            </button>
            <button class="btn btn-sm btn-danger" @click="handleDeleteOrganization(org._id)">
              <i class="fas fa-trash-alt"></i> Delete
            </button>
          </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue'
  import { fetchOrganizations, createOrganization, deleteOrganization } from '@/api'
  
  export default {
    name: 'SuperAdminOrganizations',
    setup() {
      const organizations = ref([])
      const showCreateOrgForm = ref(false)
      const newOrg = ref({ name: '', domain: '' })
  
      const loadOrganizations = async () => {
        try {
          const response = await fetchOrganizations()
          organizations.value = response.data
        } catch (error) {
          console.error('Error fetching organizations:', error)
        }
      }
  
      const handleCreateOrganization = async () => {
        try {
          await createOrganization(newOrg.value)
          loadOrganizations()
          showCreateOrgForm.value = false
          newOrg.value = { name: '', domain: '' }
        } catch (error) {
          console.error('Error creating organization:', error)
        }
      }
  
      const editOrganization = (org) => {
        // Implement edit functionality
        console.log('Edit organization:', org)
      }
  
      const handleDeleteOrganization = async (orgId) => {
        if (confirm('Are you sure you want to delete this organization?')) {
          try {
            await deleteOrganization(orgId)
            loadOrganizations()
          } catch (error) {
            console.error('Error deleting organization:', error)
          }
        }
      }
  
      onMounted(() => {
        loadOrganizations()
      })
  
      return {
        organizations,
        showCreateOrgForm,
        newOrg,
        handleCreateOrganization,
        editOrganization,
        handleDeleteOrganization
      }
    }
  }
  </script>