<template>
    <div class="courses-management">
      <h2>Course Management</h2>
      
      <button class="btn btn-primary mb-3" @click="showAddCourseModal = true">
        <i class="fas fa-plus-circle mr-2"></i>Add New Course
      </button>
      
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Type</th>
            <th>Creator</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="course in courses" :key="course._id">
            <td>{{ course.title }}</td>
            <td>{{ course.description }}</td>
            <td>{{ course.type }}</td>
            <td>{{ course.creator.name }}</td>
            <td>
              <button class="btn btn-sm btn-info mr-2" @click="editCourse(course)" title="Edit">
                <i class="fas fa-edit"></i>
              </button>
              <button class="btn btn-sm btn-danger" @click="deleteCourse(course._id)" title="Delete">
                <i class="fas fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <!-- Add Course Modal -->
      <div class="modal" tabindex="-1" role="dialog" v-if="showAddCourseModal">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Add New Course</h5>
              <button type="button" class="close" @click="showAddCourseModal = false">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="addCourse">
                <div class="form-group">
                  <label for="title">Title</label>
                  <input type="text" class="form-control" id="title" v-model="newCourse.title" required>
                </div>
                <div class="form-group">
                  <label for="description">Description</label>
                  <textarea class="form-control" id="description" v-model="newCourse.description" required></textarea>
                </div>
                <div class="form-group">
                  <label for="type">Type</label>
                  <select class="form-control" id="type" v-model="newCourse.type" required>
                    <option value="course">Course</option>
                    <option value="workshop">Workshop</option>
                  </select>
                </div>
                <button type="submit" class="btn btn-primary">Add Course</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted, computed } from 'vue'
  import { useStore } from 'vuex'
  import { fetchCourses, createCourse} from '@/api'
  
  export default {
    name: 'CoursesManagement',
    setup() {
      const store = useStore()
      const courses = ref([])
      const showAddCourseModal = ref(false)
      const newCourse = ref({
        title: '',
        description: '',
        type: 'course'
      })
  
      const currentUser = computed(() => store.getters.currentUser)
  
      const loadCourses = async () => {
        try {
          const response = await fetchCourses()
          courses.value = response.data
        } catch (error) {
          console.error('Error fetching courses:', error)
          // Implement error handling (e.g., show an error message to the user)
        }
      }
  
      const addCourse = async () => {
        try {
          const courseData = {
            ...newCourse.value,
            creator: '66d025ce972d8dcae4cb6dbd',
            organization: currentUser.value.organization
          }
          await createCourse(courseData)
          await loadCourses()
          showAddCourseModal.value = false
          newCourse.value = { title: '', description: '', type: 'course' }
        } catch (error) {
          console.error('Error creating course:', error)
          // Implement error handling
        }
      }
  
      const editCourse = (course) => {
        // Implement edit functionality
        console.log('Edit course:', course)
        // You would typically set the course data to a form and show an edit modal
      }
  
      const deleteCourse = async (courseId) => {
        if (confirm('Are you sure you want to delete this course?')) {
          try {
            await deleteCourse(courseId)
            await loadCourses()
          } catch (error) {
            console.error('Error deleting course:', error)
            // Implement error handling
          }
        }
      }
  
      onMounted(() => {
        loadCourses()
        if (!currentUser.value) {
          store.dispatch('fetchUser')
        }
      })
  
      return {
        courses,
        showAddCourseModal,
        newCourse,
        currentUser,
        addCourse,
        editCourse,
        deleteCourse
      }
    }
  }
  </script>
  
  <style scoped>
  .btn i {
    margin-right: 5px;
  }
  </style>