<template>
  <div class="dashboard-layout mt-5">
    <nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
      <a class="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="#">LMS Dashboard</a>
      <ul class="navbar-nav px-3 ml-auto">
        <li class="nav-item text-nowrap">
          <a class="nav-link" href="#" @click.prevent="logout">Sign out</a>
        </li>
      </ul>
    </nav>

    <div class="container-fluid">
      <div class="row">
        <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar">
          <div class="position-sticky pt-3">
            <ul class="nav flex-column">
              <li v-if="menuItems.length === 0">No menu items</li>
              <li class="nav-item" v-for="item in menuItems" :key="item.name">
                <router-link class="nav-link" :to="item.route" active-class="active">
                  <i :class="item.icon" class="feather mr-2"></i>
                  {{ item.label }}
                </router-link>
              </li>
            </ul>
          </div>
        </nav>

        <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2">{{ pageTitle }}</h1>
          </div>
          <router-view></router-view>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

export default {
  name: 'DashboardLayout',
  props: {
    menuItems: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const route = useRoute()

    const logout = () => {
      console.log('Logout clicked')
    }

    const pageTitle = computed(() => {
      const currentMenuItem = props.menuItems.find(item => item.route.name === route.name)
      return currentMenuItem ? currentMenuItem.label : 'Dashboard'
    })

    // Debug: Log menuItems when component mounts
    console.log('Menu Items in DashboardLayout:', props.menuItems)

    return {
      logout,
      pageTitle
    }
  }
}
</script>

<style>
/* Styles remain the same */
</style>