<template>
  <div class="guest-dashboard">
    <h1 class="mb-4 centered-text">Welcome, Guest!</h1>
    <p class="mb-4 centered-text">Explore our AI Vision demos:</p>
    <div class="row">
      <div class="col-md-6 mb-3" v-for="demo in demos" :key="demo.id">
        <div :class="['card', { 'banner-card': demo.id === 1, 'banner-card-2': demo.id === 2, 'banner-card-3': demo.id === 3, 'banner-card-4': demo.id === 4 ,'banner-card-5': demo.id === 5 }]">
          <!-- Card Header -->
          <div class="card-header text-center">
            <h5 class="card-title">{{ demo.title }}</h5>
          </div>

          <!-- Card Body -->
          <div class="card-body text-center">
          </div>

          <!-- Card Footer -->
          <div class="card-footer text-center">
            <router-link :to="demo.route" class="btn btn-primary">Try Demo</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'

export default {
  name: 'GuestDashboard',
  setup() {
    const demos = ref([
      { id: 1, title: 'Counting Faces', description: 'Count the number of faces detected in the webcam', route: '/guest/FaceCounterDemo' },  
      { id: 2, title: 'Emotion Detection', description: 'Detect emotions using facial landmarks', route: '/guest/emotion-detection' },
      { id: 3, title: 'Drowsiness Detection', description: 'Detect Drowsiness while driving and alert', route: '/guest/gesture-recognition' },
      { id: 4, title: 'Social Distancing', description: 'Detects distance between people ', route: '/guest/social-distancing' },
      { id: 5, title: 'Laws of Motion', description: 'Demonstraing Newton\'s of Motion', route: '/guest/laws-of-motion' },
    ])

    onMounted(() => {
      // Initialize guest metrics in local storage
      if (!localStorage.getItem('guestMetrics')) {
        localStorage.setItem('guestMetrics', JSON.stringify({
          emotionDetection: 0,
          gestureRecognition: 0,
          signLanguage: 0
        }))
      }
    })

    return {
      demos
    }
  }
}
</script>

<style scoped>
.guest-dashboard {
  background-color: rgb(114, 211, 213);
  padding: 20px; /* Optional: adds some padding around the content */
  margin: 0px;
  height:auto
}

.centered-text {
  text-align: center; /* Centers the text horizontally */
  color: white; /* Sets the text color to white */
}

.card {
  width: 100%; /* Ensures card takes up the full width of the column */
  min-height: 200px; /* Adjust as needed for the desired card height */
  background-color: rgb(255, 204, 246); /* Sets the card background color */
  border-radius: 8px; /* Optional: adds rounded corners to the cards */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
  background-image: none; /* Default background image setting */
  background-size: cover; /* Cover the entire card */
  background-position: center; /* Center the image */
}

.card-header,
.card-body,
.card-footer {
  padding: 10px; /* Adjusted padding */
  color: black; /* Ensure card text color contrasts with background */
}

.card-header,
.card-footer {
  border: 0; /* Remove border for header and footer */
}

.card-body {
  border: 0; /* Remove border for body */
}

.card-header,
.card-body,
.card-footer {
  text-align: center; /* Centers the text horizontally */
}

.card-footer {
  padding: 5px 10px; /* Smaller padding for the footer */
}

/* Style for the card with a background image for id 1 */
.banner-card {
  background-image: url('@/assets/counting.jpeg'); /* Path to the image */
  background-size: cover; /* Cover the entire card */
  background-position: center; /* Center the image */
  color: white; /* Ensure text is visible on the banner */
  opacity: 0.8;
}

.banner-card .card-header,
.banner-card .card-body,
.banner-card .card-footer {
  background: rgba(255, 255, 255, 0.5); /* Semi-transparent background to allow image to show through */
}

/* Style for the card with a background image for id 2 */
.banner-card-2 {
  background-image: url('@/assets/Emot.jpeg'); /* Path to the image */
  background-size: cover; /* Cover the entire card */
  background-position:20% 0%; /* Center the image */
  color: white; /* Ensure text is visible on the banner */
  opacity: 0.8;
}

.banner-card-2 .card-header,
.banner-card-2 .card-body,
.banner-card-2 .card-footer {
  background: rgba(255, 255, 255, 0.5); /* Semi-transparent background to allow image to show through */
}

/* Style for the card with a background image for id 3 */
.banner-card-3 {
  background-image: url('@/assets/Drows.jpeg'); /* Path to the image */
  background-size: cover; /* Cover the entire card */
  background-position: center; /* Center the image */
  color: white; /* Ensure text is visible on the banner */
  opacity: 0.8;
}

.banner-card-3 .card-header,
.banner-card-3 .card-body,
.banner-card-3 .card-footer {
  background: rgba(255, 255, 255, 0.5); /* Semi-transparent background to allow image to show through */
}

/* Style for the card with a background image for id 4 */
.banner-card-4 {
  background-image: url('@/assets/distance.jpg'); /* Path to the image */
  background-size: cover; /* Cover the entire card */
  background-position: center; /* Center the image */
  color: white; /* Ensure text is visible on the banner */
  opacity: 0.8;
}

.banner-card-4 .card-header,
.banner-card-4 .card-body,
.banner-card-4 .card-footer {
  background: rgba(255, 255, 255, 0.5); /* Semi-transparent background to allow image to show through */
}
.banner-card-5 {
  background-image: url('@/assets/Newtons.jpg'); /* Path to the image */
  background-size: cover; /* Cover the entire card */
  background-position:80% 20%; /* Center the image */
  color: white; /* Ensure text is visible on the banner */
  opacity: 0.8;
}

.banner-card-5 .card-header,
.banner-card-5 .card-body,
.banner-card-5 .card-footer {
  background: rgba(255, 255, 255, 0.5); /* Semi-transparent background to allow image to show through */
}
</style>
