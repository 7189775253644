import { createRouter, createWebHistory } from 'vue-router'
import WelcomeScreen from '@/views/WelcomeScreen.vue'
import SuperAdminDashboard from '@/views/superadmin/Dashboard.vue'
import SuperAdminOrganizations from '@/views/superadmin/Organizations.vue'
import SuperAdminUsers from '@/views/superadmin/Users.vue'
import SuperAdminCourses from '@/views/superadmin/Courses.vue'
import SuperAdminStatistics from '@/views/superadmin/Statistics.vue'

import GuestDashboard from '@/views/guest/GuestDashboard.vue'
import EmotionDetectionDemo from '@/views/guest/EmotionDetection.vue'
import GestureRecognitionDemo from '@/views/guest/DrowsinessDetection.vue'
import FaceCounterDemo from '@/views/guest/CountingFaces.vue'
import SocialDistancing from '@/views/guest/SocialDistancing.vue'
import LawsOfMotion from '@/views/guest/LawsOfMotion.vue'
const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: WelcomeScreen
  },
  {
    path: '/guest/dashboard',
    name: 'GuestDashboard',
    component: GuestDashboard
  },
  {
    path: '/guest/emotion-detection',
    name: 'EmotionDetectionDemo',
    component: EmotionDetectionDemo
  },
  {
    path: '/guest/gesture-recognition',
    name: 'GestureRecognitionDemo',
    component: GestureRecognitionDemo
  },
  {
    path: '/guest/laws-of-motion',
    name: 'LawsOfMotion',
    component: LawsOfMotion
  },
  {
    path: '/guest/FaceCounterDemo',
    name: 'FaceCounterDemo',
    component: FaceCounterDemo
  },
  {
    path: '/guest/social-distancing',
    name: 'SocialDistancing',
    component: SocialDistancing
  },
  {
    path: '/super-admin',
    component: SuperAdminDashboard,
    children: [
      { path: '', redirect: { name: 'SuperAdminDashboard' } },
      { path: 'dashboard', redirect: { name: 'SuperAdminDashboard' } },

      
      { path: 'organizations', name: 'SuperAdminOrganizations', component: SuperAdminOrganizations },
      { path: 'users', name: 'SuperAdminUsers', component: SuperAdminUsers },
      { path: 'courses', name: 'SuperAdminCourses', component: SuperAdminCourses },
      { path: 'statistics', name: 'SuperAdminStatistics', component: SuperAdminStatistics }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router