import axios from 'axios'
import store from '@/store'  // Import your Vuex store

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'https://lms.punyahacademy.com/api'
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.state.token
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access (e.g., redirect to login)
      store.dispatch('logout')
      // You might want to use router to redirect to login page
    }
    return Promise.reject(error)
  }
)

export default {
  install: (app) => {
    app.config.globalProperties.$axios = axiosInstance
  }
}

export { axiosInstance }