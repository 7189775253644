<template>
  <div class="container-fluid mt-4">
    <h1 class="text-center mb-4">Drowsiness Detection System</h1>
    <div class="row">
      <div class="col-lg-6">
        <div class="video-container mb-3">
          <video ref="videoRef" autoplay playsinline class="webcam-video"></video>
          <canvas ref="canvasRef" class="webcam-canvas"></canvas>
        </div>
        <div class="text-center">
          <button class="btn btn-primary" @click="toggleDetection">
            {{ isDetecting ? 'Stop Detection' : 'Start Detection' }}
          </button>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card mb-3">
          <div class="card-header">
            <h2 class="h5 mb-0">Drowsiness Status</h2>
          </div>
          <div class="card-body">
            <p class="h3" :class="{ 'text-danger': isDrowsy, 'text-success': !isDrowsy }">
              {{ isDrowsy ? 'DROWSY - ALERT!' : 'ALERT' }}
            </p>
            <p>Eye Aspect Ratio (EAR): {{ currentEAR }}</p>
            <p>Frames with Closed Eyes: {{ closedEyeFrames }}</p>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h2 class="h5 mb-0">Detection Settings</h2>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <label for="earThreshold" class="form-label">EAR Threshold</label>
              <input type="range" class="form-range" id="earThreshold" v-model="earThreshold" min="1.5" max="2.5" step="0.01">
              <span>{{ earThreshold }}</span>
            </div>
            <div class="mb-3">
              <label for="closedEyeFramesThreshold" class="form-label">Closed Eye Frames Threshold</label>
              <input type="range" class="form-range" id="closedEyeFramesThreshold" v-model="closedEyeFramesThreshold" min="1" max="50" step="1">
              <span>{{ closedEyeFramesThreshold }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h2 class="h5 mb-0">How Drowsiness Detection Works</h2>
          </div>
          <div class="card-body">
            <h3 class="h6">Eye Aspect Ratio (EAR)</h3>
            <p>We use the Eye Aspect Ratio (EAR) to detect eye closure. EAR is calculated using the following formula:</p>
            <pre>
              EAR = (||p2-p6|| + ||p3-p5||) / (2 * ||p1-p4||)
              
              Where p1, ..., p6 are the 2D landmark points of the eye.
            </pre>
            <p>A lower EAR indicates a more closed eye. We consider the eye closed if the EAR falls below the threshold.</p>
            
            <h3 class="h6">Drowsiness Detection</h3>
            <p>Drowsiness is detected by monitoring the number of consecutive frames where the eyes are considered closed:</p>
            <pre>
              If EAR &lt; EAR_THRESHOLD:
                  increment closed_eye_frames
              Else:
                  reset closed_eye_frames to 0
                  
              If closed_eye_frames &gt; CLOSED_EYE_FRAMES_THRESHOLD:
                  Alert for drowsiness
            </pre>
            <p>This method allows for brief eye closures (blinking) without triggering an alert, while detecting prolonged eye closure that may indicate drowsiness.</p>
          </div>
        </div>
      </div>
    </div>
    <audio ref="alertSound" src="https://ivislabs.s3.ap-south-1.amazonaws.com/warning.mp3" preload="auto"></audio>

  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { FaceLandmarker, FilesetResolver } from '@mediapipe/tasks-vision';

export default {
  name: 'DrowsinessDetection',
  setup() {
    const videoRef = ref(null);
    const canvasRef = ref(null);
    const isDetecting = ref(false);
    const isDrowsy = ref(false);
    const currentEAR = ref(0);
    const closedEyeFrames = ref(0);
    const earThreshold = ref(1.5);
    const closedEyeFramesThreshold = ref(20);
    const alertSound = ref(null);

    let faceLandmarker = null;

    const startWebcam = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        if (videoRef.value) {
          videoRef.value.srcObject = stream;
        }
      } catch (error) {
        console.error('Error accessing the webcam:', error);
      }
    };

    const stopWebcam = () => {
      if (videoRef.value && videoRef.value.srcObject) {
        const tracks = videoRef.value.srcObject.getTracks();
        tracks.forEach(track => track.stop());
        videoRef.value.srcObject = null;
      }
    };
    const playAlertSound = () => {
      if (alertSound.value) {
        alertSound.value.play().catch(error => console.error('Error playing sound:', error));
      }
    };
    const initializeFaceLandmarker = async () => {
      const filesetResolver = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm"
      );
      faceLandmarker = await FaceLandmarker.createFromOptions(filesetResolver, {
        baseOptions: {
          modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
          delegate: "GPU"
        },
        outputFaceBlendshapes: true,
        runningMode: "VIDEO",
        numFaces: 1
      });
    };

    const detectDrowsiness = async () => {
      if (!faceLandmarker) return;

      if (videoRef.value.videoWidth > 0) {
        const detections = faceLandmarker.detectForVideo(videoRef.value, performance.now());
        drawResults(detections);
        if (detections.faceLandmarks && detections.faceLandmarks.length > 0) {
          const landmarks = detections.faceLandmarks[0];
          calculateEAR(landmarks);
        }
      }

      if (isDetecting.value) {
        requestAnimationFrame(detectDrowsiness);
      }
    };

    const drawResults = (detections) => {
      const ctx = canvasRef.value.getContext('2d');
      ctx.clearRect(0, 0, canvasRef.value.width, canvasRef.value.height);
      
      if (detections.faceLandmarks && detections.faceLandmarks.length > 0) {
        const landmarks = detections.faceLandmarks[0];
        drawEyeRectanglesAndPoints(ctx, landmarks);
      }
    };

    const drawEyeRectanglesAndPoints = (ctx, landmarks) => {
      const leftEyePoints = getEyePoints(landmarks, FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS);
      const rightEyePoints = getEyePoints(landmarks, FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS);
      
      drawEyeRectangle(ctx, leftEyePoints);
      drawEyeRectangle(ctx, rightEyePoints);
      
      drawEyePoints(ctx, leftEyePoints, '#FF00FF');  // Magenta for left eye
      drawEyePoints(ctx, rightEyePoints, '#00FFFF'); // Cyan for right eye
    };

    const getEyePoints = (landmarks, eyeLandmarks) => {
      return eyeLandmarks.flatMap(pair => 
        Array.from({ length: pair.end - pair.start + 1 }, (_, i) => pair.start + i)
      ).map(index => ({
        x: landmarks[index].x,
        y: landmarks[index].y
      }));
    };

    const drawEyeRectangle = (ctx, points) => {
      const { width, height } = canvasRef.value;
      
      // Calculate the bounding box of the eye
      const minX = Math.min(...points.map(p => p.x)) * width;
      const maxX = Math.max(...points.map(p => p.x)) * width;
      const minY = Math.min(...points.map(p => p.y)) * height;
      const maxY = Math.max(...points.map(p => p.y)) * height;
      
      const rectWidth = maxX - minX;
      const rectHeight = maxY - minY;
      
      // Add a small padding
      const padding = Math.min(rectWidth, rectHeight) * 0.1; // 10% of the smaller dimension
      const rectX = minX - padding;
      const rectY = minY - padding;
      const paddedWidth = rectWidth + (padding * 2);
      const paddedHeight = rectHeight + (padding * 2);

      // Draw the rectangle
      ctx.strokeStyle = isDrowsy.value ? '#FF3030' : '#30FF30';
      ctx.lineWidth = 2;
      ctx.strokeRect(rectX, rectY, paddedWidth, paddedHeight);
      
      
      //const aspectRatio = paddedWidth / paddedHeight;
      //ctx.fillText(`AR: ${aspectRatio.toFixed(2)}`, rectX, rectY + paddedHeight + 20);
    };

    const drawEyePoints = (ctx, points, color) => {
      const { width, height } = canvasRef.value;
      
      ctx.fillStyle = color;
      points.forEach(point => {
        const x = point.x * width;
        const y = point.y * height;
        ctx.beginPath();
        ctx.arc(x, y, 1, 0, 1 * Math.PI);
        ctx.fill();
      });
    };

    const calculateEAR = (landmarks) => {
      const leftEAR = getEAR(landmarks, FaceLandmarker.FACE_LANDMARKS_LEFT_EYE);
      const rightEAR = getEAR(landmarks, FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE);
      currentEAR.value = (leftEAR + rightEAR) / 2;

      if (currentEAR.value < earThreshold.value) {
        closedEyeFrames.value++;
        if (closedEyeFrames.value > closedEyeFramesThreshold.value) {
          if (!isDrowsy.value) {
            playAlertSound();
          }
          isDrowsy.value = true;
        }
      } else {
        closedEyeFrames.value = 0;
        isDrowsy.value = false;
      }
    };
    const getEAR = (landmarks, eyeLandmarks) => {
      const points = eyeLandmarks.flatMap(pair => 
        Array.from({ length: pair.end - pair.start + 1 }, (_, i) => pair.start + i)
      ).map(index => landmarks[index]);

      const verticalDist1 = euclideanDistance(points[1], points[5]);
      const verticalDist2 = euclideanDistance(points[2], points[4]);
      const horizontalDist = euclideanDistance(points[0], points[3]);

      return (verticalDist1 + verticalDist2) / (2 * horizontalDist);
    };

    const euclideanDistance = (point1, point2) => {
      return Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2));
    };

    const toggleDetection = async () => {
      isDetecting.value = !isDetecting.value;
      if (isDetecting.value) {
        if (!faceLandmarker) {
          await initializeFaceLandmarker();
        }
        detectDrowsiness();
      } else {
        const ctx = canvasRef.value.getContext('2d');
        ctx.clearRect(0, 0, canvasRef.value.width, canvasRef.value.height);
        isDrowsy.value = false;
        currentEAR.value = 0;
        closedEyeFrames.value = 0;
      }
    };

    onMounted(async () => {
      await startWebcam();
      await initializeFaceLandmarker();
    });

    onUnmounted(() => {
      stopWebcam();
    });

    return {
      videoRef,
      canvasRef,
      isDetecting,
      isDrowsy,
      currentEAR,
      closedEyeFrames,
      earThreshold,
      closedEyeFramesThreshold,
      toggleDetection,
      alertSound
    };
  }
};
</script>

<style scoped>
.video-container {
  position: relative;
  width: 640px;
  height: 480px;
  margin: 0 auto;
}

.webcam-video, .webcam-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 640px;
  height: 480px;
}

.webcam-video {
  transform: scaleX(-1);
  background-color: #000;
}

.webcam-canvas {
  transform: scaleX(-1);
}

pre {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
}

@media (max-width: 992px) {
  .video-container {
    width: 100%;
    height: auto;
    aspect-ratio: 4 / 3;
  }
  
  .webcam-video, .webcam-canvas {
    width: 100%;
    height: 100%;
  }
}
pre {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
}
</style>