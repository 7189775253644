// src/plugins/auth.js
import axios from 'axios'

const authPlugin = {
  install(app) {
    const authApi = axios.create({
      baseURL: 'https://auth.ivislabs.com',
      timeout: 5000,
      headers: {
        'Content-Type': 'application/json'
      }
    })

    const auth = {
      async login(credentials) {
        try {
          const response = await authApi.post('/login', credentials)
          localStorage.setItem('token', response.data.token)
          return response.data
        } catch (error) {
          console.error('Login error:', error)
          throw error
        }
      },

      async logout() {
        try {
          await authApi.post('/logout')
          localStorage.removeItem('token')
        } catch (error) {
          console.error('Logout error:', error)
          throw error
        }
      },

      async getUser() {
        try {
          const token = localStorage.getItem('token')
          if (!token) return null

          const response = await authApi.get('/user', {
            headers: { Authorization: `Bearer ${token}` }
          })
          return response.data
        } catch (error) {
          console.error('Get user error:', error)
          throw error
        }
      },

      isAuthenticated() {
        return !!localStorage.getItem('token')
      }
    }

    app.config.globalProperties.$auth = auth
    app.provide('auth', auth)
  }
}

export default authPlugin