<template>
  <DashboardLayout :menuItems="menuItems">
    <router-view></router-view>
  </DashboardLayout>
</template>

<script>
import { ref, onMounted } from 'vue'
import DashboardLayout from '@/components/DashboardLayout.vue'

export default {
  name: 'SuperAdminDashboard',
  components: {
    DashboardLayout
  },
  setup() {
    const menuItems = ref([
      { name: 'organizations', label: 'Organizations', route: { name: 'SuperAdminOrganizations' }, icon: 'fas fa-building' },
      { name: 'users', label: 'Users', route: { name: 'SuperAdminUsers' }, icon: 'fas fa-users' },
      { name: 'courses', label: 'Courses', route: { name: 'SuperAdminCourses' }, icon: 'fas fa-book' },
      { name: 'statistics', label: 'Statistics', route: { name: 'SuperAdminStatistics' }, icon: 'fas fa-chart-bar' }
    ])

    onMounted(() => {
      // Debug: Log menuItems when component mounts
      console.log('Menu Items in SuperAdminDashboard:', menuItems.value)
    })

    return {
      menuItems
    }
  }
}
</script>