<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue';
import * as THREE from 'three';

const canvasRef = ref(null);

// Reactive state for simulation parameters
const gravity = ref(-9.8);
const bounciness = ref(0.8);
const mass = ref(1);
const appliedForce = ref({ x: 0, y: 0 });

// Reactive state for ball properties
const ballPosition = ref({ x: 0, y: 5, z: 0 });
const ballVelocity = ref({ x: 0, y: 0, z: 0 });

let scene, camera, renderer, ball, floor, force, velocity;
let isAnimating = true;

const init = () => {
  // Create scene
  scene = new THREE.Scene();
  scene.background = new THREE.Color(0x87CEEB);

  // Create camera
  camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
  camera.position.set(0, 5, 10);
  camera.lookAt(0, 0, 0);

  // Create renderer
  renderer = new THREE.WebGLRenderer({ canvas: canvasRef.value, antialias: true });
  updateRendererSize();

  // Create ball
  const ballGeometry = new THREE.SphereGeometry(0.5, 32, 32);
  const ballMaterial = new THREE.MeshPhongMaterial({ color: 0xff0000 });
  ball = new THREE.Mesh(ballGeometry, ballMaterial);
  ball.position.set(ballPosition.value.x, ballPosition.value.y, ballPosition.value.z);
  scene.add(ball);

  // Create floor
  const floorGeometry = new THREE.PlaneGeometry(10, 10);
  const floorMaterial = new THREE.MeshPhongMaterial({ color: 0x999999, side: THREE.DoubleSide });
  floor = new THREE.Mesh(floorGeometry, floorMaterial);
  floor.rotation.x = Math.PI / 2;
  scene.add(floor);

  // Add lights
  const ambientLight = new THREE.AmbientLight(0x404040);
  scene.add(ambientLight);
  const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
  directionalLight.position.set(1, 1, 1);
  scene.add(directionalLight);

  // Initialize physics variables
  force = new THREE.Vector3(0, gravity.value, 0);
  velocity = new THREE.Vector3(0, 0, 0);

  // Add event listeners
  window.addEventListener('resize', updateRendererSize);

  animate();
};

const updateRendererSize = () => {
  const containerWidth = canvasRef.value.parentElement.clientWidth;
  const containerHeight = canvasRef.value.parentElement.clientHeight;
  renderer.setSize(containerWidth, containerHeight);
  camera.aspect = containerWidth / containerHeight;
  camera.updateProjectionMatrix();
};

const updatePhysics = (deltaTime) => {
  // Apply gravity and user-applied force
  force.set(appliedForce.value.x, gravity.value + appliedForce.value.y, 0);
  
  // Second Law: F = ma, so a = F/m
  const acceleration = force.clone().divideScalar(mass.value);
  
  // Update velocity
  velocity.add(acceleration.multiplyScalar(deltaTime));
  
  // Update position
  ball.position.add(velocity.clone().multiplyScalar(deltaTime));
  
  // Simple floor collision (for bouncing)
  if (ball.position.y < 0.5) {
    ball.position.y = 0.5;
    velocity.y *= -bounciness.value;
  }
  
  // Update reactive state
  ballPosition.value = { x: ball.position.x, y: ball.position.y, z: ball.position.z };
  ballVelocity.value = { x: velocity.x, y: velocity.y, z: velocity.z };
  
  // Reset applied force
  appliedForce.value = { x: 0, y: 0 };
};

const animate = () => {
  if (!isAnimating) return;
  requestAnimationFrame(animate);
  updatePhysics(0.016);  // Assume 60 FPS for simplicity
  renderer.render(scene, camera);
};

const applyForce = (x, y) => {
  appliedForce.value = { x, y };
};

const resetSimulation = () => {
  ball.position.set(0, 5, 0);
  velocity.set(0, 0, 0);
  ballPosition.value = { x: 0, y: 5, z: 0 };
  ballVelocity.value = { x: 0, y: 0, z: 0 };
};

onMounted(() => {
  init();
});

onUnmounted(() => {
  isAnimating = false;
  window.removeEventListener('resize', updateRendererSize);
  renderer.dispose();
});

// Watch for changes in simulation parameters
watch([gravity, mass, bounciness], () => {
  // Update simulation parameters when they change
  force.y = gravity.value;
});
</script>

<template>
  <div class="simulation-container">
    <div class="canvas-container">
      <canvas ref="canvasRef"></canvas>
    </div>
    <div class="controls">
      <h2>Newton's Laws of Motion</h2>
      
      <div class="control-group">
        <label>
          Gravity:
          <input type="range" v-model="gravity" min="-20" max="0" step="0.1">
          {{ gravity.toFixed(1) }} m/s²
        </label>
      </div>
      
      <div class="control-group">
        <label>
          Mass:
          <input type="range" v-model="mass" min="0.1" max="10" step="0.1">
          {{ mass.toFixed(1) }} kg
        </label>
      </div>
      
      <div class="control-group">
        <label>
          Bounciness:
          <input type="range" v-model="bounciness" min="0" max="1" step="0.05">
          {{ bounciness.toFixed(2) }}
        </label>
      </div>
      
      <div class="control-group">
        <button @click="applyForce(0, 20)">Apply Upward Force</button>
        <button @click="applyForce(-10, 0)">Apply Left Force</button>
        <button @click="applyForce(10, 0)">Apply Right Force</button>
      </div>
      
      <button @click="resetSimulation">Reset Simulation</button>
      
      <div class="ball-info">
        <h3>Ball State:</h3>
        <p>Position: ({{ ballPosition.x.toFixed(2) }}, {{ ballPosition.y.toFixed(2) }})</p>
        <p>Velocity: ({{ ballVelocity.x.toFixed(2) }}, {{ ballVelocity.y.toFixed(2) }}) m/s</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.simulation-container {
  display: flex;
  height: 100vh;
  width: 100%;
}

.canvas-container {
  flex: 1;
  min-width: 0; /* Allows the container to shrink below its content size */
}

canvas {
  width: 100%;
  height: 100%;
  display: block;
}

.controls {
  width: 300px;
  padding: 20px;
  background-color: #f0f0f0;
  overflow-y: auto;
}

.control-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="range"] {
  width: 100%;
}

button {
  margin-right: 5px;
  margin-bottom: 5px;
}

.ball-info {
  margin-top: 20px;
  border-top: 1px solid #ccc;
  padding-top: 10px;
}
</style>