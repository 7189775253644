<template>
    <h1> Statistics screen</h1>

</template>
<script>

export default({
name:'StatisticsView'
})
</script>
