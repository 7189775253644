<template>
  <div class="container-fluid bg-light py-4">
    <div class="card-header bg-info text-white py-3 mb-4" style="border-radius:15px">
      <h1 class="text-center">📸 Facial Expression Analyzer 🧠</h1>
    </div>
    <div class="row">
      <!-- Left column: Video feed -->
      <div class="col-lg-6">
        <div class="video-container mb-3">
          <video ref="videoRef" autoplay playsinline class="webcam-video"></video>
          <canvas ref="canvasRef" class="webcam-canvas"></canvas>
        </div>
        <div class="text-center mb-3">
          <button class="btn btn-lg bg-info text-white" @click="toggleDetection">
            {{ isDetecting ? '🛑 Stop Detection' : '🚀 Start Detection' }}
          </button>
        </div>
      </div>
      
      <!-- Right column: Results and explanations -->
      <div class="col-lg-6">
        <div class="card mb-4">
          <div class="card-header bg-info text-white">
            <h2 class="h4 mb-0">😎 Your Expressions</h2>
          </div>
          <div class="card-body">
            <p>🤨 Eyebrows Raised: {{ expressions.eyebrowsRaised ? 'Yes!' : 'Not yet' }}</p>
            <p>😮 Mouth Open: {{ expressions.mouthOpen ? 'Wide open!' : 'Closed' }}</p>
            <p>😃 Smiling: {{ expressions.smiling ? 'Cheese!' : 'Not quite' }}</p>
          </div>
        </div>
        <div class="card">
          <div class="card-header bg-info text-white">
            <h2 class="h4 mb-0">🎛️ Tweak the AI Brain</h2>
          </div>
          <div class="card-body">
            <p>Adjust these sliders to change how sensitive the AI is to each expression. Higher values mean it takes more movement to detect the expression.</p>
            <div v-for="(threshold, expression) in expressionThresholds" :key="expression" class="mb-3">
              <label :for="expression" class="form-label">{{ getExpressionLabel(expression) }}</label>
              <div class="d-flex align-items-center">
                <input 
                  type="range" 
                  class="form-range flex-grow-1 me-2" 
                  :id="expression" 
                  v-model="expressionThresholds[expression]" 
                  min="0" 
                  max="1" 
                  step="0.01"
                >
                <span class="badge bg-info">{{ expressionThresholds[expression] }}</span>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div class="card mb-4">
          <div class="card-header bg-info text-white">
            <h2 class="h4 mb-0">🧠 How It Works</h2>
          </div>
          <div class="card-body">
            <p>Ever wonder how Snapchat filters know where to put those dog ears? It's all about facial landmarks! Here's a quick breakdown:</p>
            <ul>
              <li>Our AI brain looks for specific points on your face, like the corners of your eyes and mouth.</li>
              <li>It then uses math to measure distances between these points.</li>
              <li>For example, if the distance between your eyebrows and eyes suddenly increases, we know you're raising your eyebrows!</li>
              <li>Same goes for your smile - we check if the corners of your mouth are higher than usual.</li>
            </ul>
            <p>Cool, right? It's like your face is a connect-the-dots puzzle for our AI!</p>
          </div>
        </div>

        
      </div>
    </div>
</template>

<script>
import { ref, reactive, onMounted, onUnmounted } from 'vue';
import { FaceLandmarker, FilesetResolver } from '@mediapipe/tasks-vision';

export default {
  name: 'FacialExpressionAnalyzer',
  setup() {
    const videoRef = ref(null);
    const canvasRef = ref(null);
    const isDetecting = ref(false);
    const expressions = reactive({
      eyebrowsRaised: false,
      mouthOpen: false,
      smiling: false
    });
    let faceLandmarker = null;

    const expressionThresholds = reactive({
      eyebrowRaise: 0.2,
      mouthOpen: 0.3,
      smile: 0.2,
    });

    const startWebcam = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        if (videoRef.value) {
          videoRef.value.srcObject = stream;
        }
      } catch (error) {
        console.error('Error accessing the webcam:', error);
      }
    };

    const stopWebcam = () => {
      if (videoRef.value && videoRef.value.srcObject) {
        const tracks = videoRef.value.srcObject.getTracks();
        tracks.forEach(track => track.stop());
        videoRef.value.srcObject = null;
      }
    };

    const initializeFaceLandmarker = async () => {
      const filesetResolver = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm"
      );
      faceLandmarker = await FaceLandmarker.createFromOptions(filesetResolver, {
        baseOptions: {
          modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
          delegate: "GPU"
        },
        outputFaceLandmarks: true,
        runningMode: "VIDEO",
        numFaces: 1
      });
    };

    const detectExpressions = async () => {
      if (!faceLandmarker) return;

      if (videoRef.value.videoWidth > 0) {
        const detections = faceLandmarker.detectForVideo(videoRef.value, performance.now());
        drawResults(detections);
        if (detections.faceLandmarks && detections.faceLandmarks.length > 0) {
          const landmarks = detections.faceLandmarks[0];
          calculateExpressions(landmarks);
        }
      }

      if (isDetecting.value) {
        requestAnimationFrame(detectExpressions);
      }
    };

    const drawResults = (detections) => {
      const ctx = canvasRef.value.getContext('2d');
      ctx.clearRect(0, 0, canvasRef.value.width, canvasRef.value.height);
      
      if (detections.faceLandmarks) {
        for (const landmarks of detections.faceLandmarks) {
          drawLandmarks(ctx, landmarks, [
            ...FaceLandmarker.FACE_LANDMARKS_LEFT_EYE,
            ...FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW,
            ...FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE,
            ...FaceLandmarker.FACE_LANDMARKS_RIGHT_EYEBROW,
            ...FaceLandmarker.FACE_LANDMARKS_LIPS
          ]);
        }
      }
    };

    const drawLandmarks = (ctx, landmarks, indices) => {
      ctx.fillStyle = '#0dcaf0';
      for (const indexPair of indices) {
        for (let i = indexPair.start; i <= indexPair.end; i++) {
          const x = landmarks[i].x * canvasRef.value.width;
          const y = landmarks[i].y * canvasRef.value.height;
          ctx.beginPath();
          ctx.arc(x, y, 1, 1, 1 * Math.PI);
          ctx.fill();
        }
      }
    };

    const calculateExpressions = (landmarks) => {
      // Eyebrows raised
      const leftEyebrowY = landmarks[282].y;
      const rightEyebrowY = landmarks[52].y;
      const leftEyeY = landmarks[159].y;
      const rightEyeY = landmarks[386].y;
      const eyebrowRaiseLeft = leftEyeY - leftEyebrowY;
      const eyebrowRaiseRight = rightEyeY - rightEyebrowY;
      expressions.eyebrowsRaised = (eyebrowRaiseLeft > expressionThresholds.eyebrowRaise) || 
                                   (eyebrowRaiseRight > expressionThresholds.eyebrowRaise);

      // Mouth open
      const upperLipY = landmarks[13].y;
      const lowerLipY = landmarks[14].y;
      const mouthOpenness = lowerLipY - upperLipY;
      expressions.mouthOpen = mouthOpenness > expressionThresholds.mouthOpen;

      // Smiling
      const leftMouthCornerX = landmarks[61].x;
      const rightMouthCornerX = landmarks[291].x;
      const upperLipX = landmarks[0].x;
      const mouthWidth = rightMouthCornerX - leftMouthCornerX;
      const smileLeft = upperLipX - leftMouthCornerX;
      const smileRight = rightMouthCornerX - upperLipX;
      expressions.smiling = (smileLeft > expressionThresholds.smile * mouthWidth) && 
                            (smileRight > expressionThresholds.smile * mouthWidth);
    };

    const clearCanvas = () => {
      const ctx = canvasRef.value.getContext('2d');
      ctx.clearRect(0, 0, canvasRef.value.width, canvasRef.value.height);
    };

    const toggleDetection = async () => {
      isDetecting.value = !isDetecting.value;
      if (isDetecting.value) {
        if (!faceLandmarker) {
          await initializeFaceLandmarker();
        }
        detectExpressions();
      } else {
        clearCanvas();
        // Reset expressions
        Object.keys(expressions).forEach(key => expressions[key] = false);
      }
    };

    const getExpressionLabel = (expression) => {
      const labels = {
        eyebrowRaise: '🤨 Eyebrow Raise Sensitivity',
        mouthOpen: '😮 Mouth Open Sensitivity',
        smile: '😃 Smile Sensitivity'
      };
      return labels[expression] || expression;
    };

    onMounted(async () => {
      await startWebcam();
      await initializeFaceLandmarker();
    });

    onUnmounted(() => {
      stopWebcam();
    });

    return {
      videoRef,
      canvasRef,
      isDetecting,
      toggleDetection,
      expressionThresholds,
      expressions,
      getExpressionLabel
    };
  }
};
</script>

<style scoped>
.video-container {
  position: relative;
  width: 100%;
  max-width: 640px;
  aspect-ratio: 4 / 3;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.webcam-video, .webcam-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.webcam-video {
  transform: scaleX(-1);
  background-color: #000;
}

.webcam-canvas {
  transform: scaleX(-1);
}

h1, h2 {
  font-family: 'Comic Sans MS', cursive, sans-serif;
}

.btn-lg {
  font-size: 1.2rem;
  padding: 0.75rem 1.5rem;
}

.card {
  border: none;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
}

.card-header {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

@media (max-width: 992px) {
  .col-lg-6 {
    margin-bottom: 2rem;
  }
}
</style>