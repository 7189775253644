<template>
  <div class="container-fluid bg-light">
    <div class="card-header bg-info text-white py-3 my-3" style="border-radius:15px">
      <h3 class="text-center">Counting Faces with AI🕵️‍♂️</h3>
    </div>
    <div class="row">
      <!-- Video feed and controls -->
      <div class="col-lg-6">
        <div class="video-container mb-3">
          <video ref="videoRef" autoplay playsinline class="webcam-video"></video>
          <canvas ref="canvasRef" class="webcam-canvas"></canvas>
        </div>
        <div class="text-center mb-3">
          <button class="btn btn-lg" :class="isDetecting ? 'btn-danger' : 'btn-info text-white'" @click="toggleDetection">
            {{ isDetecting ? '🛑 Stop Detection' : '🚀 Start Detection' }}
          </button>
        </div>
      </div>

      <!-- Explanation and fun facts -->
      <div class="col-lg-6">
        <div class="card bg-info text-white mb-2">
          <div class="card-body text-center">
            <h2 class="card-title h4">Faces Spotted</h2>
            <p class="display-5">{{ faceCount }}</p>
          </div>
        </div>

        <!-- Detection Controls -->
        <div class="controls-panel card p-3 mb-3">
          <h4>Detection Controls</h4>
          <div class="form-check form-switch mb-2">
            <input class="form-check-input" type="checkbox" v-model="showBoundingBox" id="showBoundingBox">
            <label class="form-check-label" for="showBoundingBox">Show Bounding Box</label>
          </div>

          <div class="mb-2">
            <label for="detectionThreshold" class="form-label">Detection Threshold: {{ detectionThreshold }}</label>
            <input type="range" class="form-range" min="0" max="1" step="0.1" v-model="detectionThreshold" id="detectionThreshold">
          </div>

          <div class="mb-2">
            <label for="maxFaces" class="form-label">Max Faces to Detect: {{ maxFaces }}</label>
            <input type="number" class="form-control" v-model="maxFaces" id="maxFaces" min="1" max="10">
          </div>

          <div class="mb-2">
            <label for="boxColor" class="form-label">Bounding Box Color:</label>
            <input type="color" class="form-control form-control-color" v-model="boxColor" id="boxColor">
          </div>

          <div class="form-check form-switch mb-2">
            <input class="form-check-input" type="checkbox" v-model="audioFeedback" id="audioFeedback">
            <label class="form-check-label" for="audioFeedback">Audio Feedback</label>
          </div>

          <div class="form-check form-switch mb-2">
            <input class="form-check-input" type="checkbox" v-model="highPerformanceMode" id="highPerformanceMode">
            <label class="form-check-label" for="highPerformanceMode">High Performance Mode</label>
          </div>

          <button @click="takeSnapshot" class="btn btn-info text-white mt-2">Take Snapshot</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from 'vue';
import { FaceDetector, FilesetResolver } from '@mediapipe/tasks-vision';

export default {
  name: 'FaceCounterDemo',
  setup() {
    const videoRef = ref(null);
    const canvasRef = ref(null);
    const isDetecting = ref(false);
    const faceCount = ref(0);
    let faceDetector = null;

    const showBoundingBox = ref(true);
    const detectionThreshold = ref(0.5);
    const maxFaces = ref(5);
    const boxColor = ref('#0dcaf0');
    const audioFeedback = ref(false);
    const highPerformanceMode = ref(false);

    const startWebcam = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        if (videoRef.value) {
          videoRef.value.srcObject = stream;
        }
      } catch (error) {
        console.error('Error accessing the webcam:', error);
      }
    };

    const stopWebcam = () => {
      if (videoRef.value && videoRef.value.srcObject) {
        const tracks = videoRef.value.srcObject.getTracks();
        tracks.forEach(track => track.stop());
        videoRef.value.srcObject = null;
      }
    };

    const initializeFaceDetector = async () => {
      const filesetResolver = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm"
      );
      faceDetector = await FaceDetector.createFromOptions(filesetResolver, {
        baseOptions: {
          modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_detector/blaze_face_short_range/float16/1/blaze_face_short_range.tflite`,
          delegate: "GPU"
        },
        runningMode: "VIDEO",
        minDetectionConfidence: parseFloat(detectionThreshold.value)
      });
    };

    const detectFaces = async () => {
      if (!faceDetector || !videoRef.value || !canvasRef.value) return;

      const video = videoRef.value;
      const canvas = canvasRef.value;

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      if (video.videoWidth > 0) {
        try {
          const detections = await faceDetector.detectForVideo(video, performance.now());
          faceCount.value = Math.min(detections.detections.length, maxFaces.value);
          drawResults(detections, canvas);
          if (audioFeedback.value && faceCount.value > 0) {
            playAudioFeedback();
          }
        } catch (error) {
          console.error("Error during face detection:", error);
          isDetecting.value = false;
        }
      }

      if (isDetecting.value) {
        if (highPerformanceMode.value) {
          setTimeout(() => requestAnimationFrame(detectFaces), 0);
        } else {
          requestAnimationFrame(detectFaces);
        }
      }
    };

    const drawResults = (detections, canvas) => {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      ctx.strokeStyle = boxColor.value;
      ctx.lineWidth = 3;
      ctx.fillStyle = boxColor.value;
      ctx.font = '16px Arial';

      for (let i = 0; i < Math.min(detections.detections.length, maxFaces.value); i++) {
        const detection = detections.detections[i];
        const bbox = detection.boundingBox;

        if (showBoundingBox.value) {
          ctx.strokeRect(bbox.originX, bbox.originY, bbox.width, bbox.height);
        }

        ctx.fillText(
          `Face ${Math.round(detection.categories[0].score * 100)}%`,
          bbox.originX,
          bbox.originY - 5
        );
      }
    };

    const clearCanvas = () => {
      const ctx = canvasRef.value.getContext('2d');
      ctx.clearRect(0, 0, canvasRef.value.width, canvasRef.value.height);
    };

    const toggleDetection = async () => {
      isDetecting.value = !isDetecting.value;
      if (isDetecting.value) {
        if (!faceDetector) {
          await initializeFaceDetector();
        }
        detectFaces();
      } else {
        clearCanvas();
        faceCount.value = 0;
      }
    };

    const takeSnapshot = async () => {
      const video = videoRef.value;
      const canvas = canvasRef.value;

      // Create a temporary canvas to combine video and detections
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = video.videoWidth;
      tempCanvas.height = video.videoHeight;
      const tempCtx = tempCanvas.getContext('2d');

      // Draw the video frame without mirroring
      tempCtx.drawImage(video, 0, 0, tempCanvas.width, tempCanvas.height);

      // Draw the detection results
      tempCtx.drawImage(canvas, 0, 0);

      // Create downloadable image
      const dataURL = tempCanvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = 'face_detection_snapshot.png';
      link.click();
    };

    const playAudioFeedback = () => {
      const audio = new Audio('https://assets.mixkit.co/active_storage/sfx/2869/2869-preview.mp3');
      audio.play();
    };

    onMounted(async () => {
      await startWebcam();
      window.addEventListener('beforeunload', stopWebcam);
    });

    onUnmounted(() => {
      stopWebcam();
      window.removeEventListener('beforeunload', stopWebcam);
    });

    watch(detectionThreshold, async () => {
      if (faceDetector) {
        await initializeFaceDetector();
      }
    });

    return {
      videoRef,
      canvasRef,
      isDetecting,
      faceCount,
      toggleDetection,
      takeSnapshot,
      showBoundingBox,
      detectionThreshold,
      maxFaces,
      boxColor,
      audioFeedback,
      highPerformanceMode
    };
  }
};
</script>

<style scoped>
.container-fluid {
  max-width: 1200px;
  margin: auto;
}

.webcam-video {
  width: 100%;
  height: auto;
  border-radius: 10px;
  transform: scaleX(1); /* Ensures normal video, no mirror effect */
}


.webcam-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  pointer-events: none;
}

.video-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
