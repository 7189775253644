import { createStore } from 'vuex'
import { axiosInstance } from '@/plugins/axios'

export default createStore({
  state: {
    user: null,
    token: null
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_TOKEN(state, token) {
      state.token = token
      localStorage.setItem('token', token)  // Store token in localStorage
    },
    CLEAR_AUTH(state) {
      state.user = null
      state.token = null
      localStorage.removeItem('token')
    }
  },
  actions: {
    async setAuth({ commit }, token) {
      commit('SET_TOKEN', token)
    },
    async fetchUserInfo({ commit }) {
      try {
        const response = await axiosInstance.get('/users')  // Adjust this endpoint as needed
        console.log(response.data);
        commit('SET_USER', response.data)
      } catch (error) {
        console.error('Error fetching user info:', error)
        commit('CLEAR_AUTH')
      }
    },
    logout({ commit }) {
      commit('CLEAR_AUTH')
      // Additional logout logic (e.g., redirecting to login page) can be added here
    }
  },
  getters: {
    isAuthenticated: state => !!state.token,
    userRole: state => state.user ? state.user.role : null
  }
})